var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          staticClass: "pa-3 ma-3",
          attrs: {
            color: _vm.cardColor,
            tile: _vm.isBatch,
            shaped: !_vm.isBatch,
          },
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "10", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: _vm.isBatch ? "Batch name" : "Template name",
                          "prepend-inner-icon": "mdi-pencil",
                          outlined: "",
                          filled: "",
                          "hide-details": "",
                          dense: "",
                        },
                        model: {
                          value: _vm.node.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.node, "name", $$v)
                          },
                          expression: "node.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-spacer"),
                  !_vm.isBatch
                    ? _c(
                        "v-menu",
                        {
                          attrs: { bottom: "", left: "", "offset-y": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { icon: "" } },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-dots-vertical"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3221905750
                          ),
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.$refs.dlgImport.show = true
                                    },
                                  },
                                },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v("Import Nanotasks..."),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.$refs.dlgCreate.shown = true
                                    },
                                  },
                                },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v("Create Nanotasks..."),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "text-caption" },
                [
                  _vm.node.statement == _vm.client.ENUM.Statement.IF
                    ? _c(
                        "v-col",
                        { staticClass: "pb-0", attrs: { cols: "12" } },
                        [
                          _c("v-icon", [
                            _vm._v("mdi-comment-question-outline"),
                          ]),
                          _vm._v(" IF condition "),
                          _c(
                            "v-card",
                            { attrs: { height: "100" } },
                            [
                              _c("codemirror", {
                                attrs: { options: _vm.cmOptions },
                                model: {
                                  value: _vm.node.condition,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.node, "condition", $$v)
                                  },
                                  expression: "node.condition",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.node.statement == _vm.client.ENUM.Statement.WHILE
                    ? _c(
                        "v-col",
                        { staticClass: "pb-0", attrs: { cols: "12" } },
                        [
                          _c("v-icon", [_vm._v("mdi-repeat")]),
                          _vm._v(" LOOP condition "),
                          _c(
                            "v-card",
                            { attrs: { height: "100" } },
                            [
                              _c("codemirror", {
                                attrs: { options: _vm.cmOptions },
                                model: {
                                  value: _vm.node.condition,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.node, "condition", $$v)
                                  },
                                  expression: "node.condition",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.node.skippable
                    ? _c(
                        "v-col",
                        { staticClass: "pb-0", attrs: { cols: "12" } },
                        [
                          _c("v-icon", [_vm._v("mdi-transit-skip")]),
                          _vm._v(" Skippable "),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.hasNanotask
                    ? _c(
                        "v-col",
                        { staticClass: "pb-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-chip",
                            {
                              attrs: {
                                dark: "",
                                label: "",
                                outlined: "",
                                color: "indigo",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.$refs.dlgList.show = true
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("mdi-file-document-multiple-outline"),
                              ]),
                              _vm._v(
                                " Nanotasks (" +
                                  _vm._s(_vm.nanotasks.length) +
                                  ") "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._l(_vm.children, function (child, idx) {
                return _c(
                  "v-row",
                  { key: idx, attrs: { justify: "end" } },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", md: "11" } },
                      [
                        _c("recursive-batch", {
                          attrs: {
                            client: _vm.client,
                            name: _vm.name,
                            "parent-params": {
                              projectName: _vm.projectName,
                              templateColor: _vm.templateColor,
                              node: child,
                              isLast: idx == _vm.children.length - 1,
                              depth: _vm.depth + 1,
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
      !_vm.isLast
        ? _c("arrow", { attrs: { depth: _vm.depth, color: _vm.templateColor } })
        : _vm._e(),
      _c("dialog-import", {
        ref: "dlgImport",
        attrs: {
          client: _vm.client,
          projectName: _vm.projectName,
          template: _vm.node.name,
        },
      }),
      _c("dialog-create", {
        ref: "dlgCreate",
        attrs: {
          client: _vm.client,
          projectName: _vm.projectName,
          template: _vm.node.name,
        },
      }),
      _c("dialog-list", {
        ref: "dlgList",
        attrs: {
          client: _vm.client,
          projectName: _vm.projectName,
          template: _vm.node.name,
          nanotasks: _vm.nanotasks,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }