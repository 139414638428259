<template>
    <v-main class="grey lighten-4">
        <page-toolbar title="Scheme">
            <template #contents>
                <v-btn text color="indigo" @click="openSchemeEditorWindow">
                    <v-icon left>mdi-file-code-outline</v-icon>
                    Open File Editor
                </v-btn>
                <v-btn text color="indigo" @click="loadFlow(false)">
                    <v-icon left>mdi-refresh</v-icon>
                    Refresh Scheme
                </v-btn>
            </template>
        </page-toolbar>

        <v-row class="justify-center">
            <v-col cols="11" lg="5" class="pa-8 pr-4">
                <config-table :config="config" />
            </v-col>
            <v-col cols="11" lg="7" class="pa-8 pl-4">
                <task-flow-card
                    :client="client"
                    :projectName="projectName"
                    :flow="flow"
                    template-color="blue-grey lighten-4"
                    />
            </v-col>
        </v-row>

        <tutti-snackbar ref="snackbar" />

    </v-main>
</template>

<script>
import PageToolbar from '@/components/ui/PageToolbar'
import ConfigTable from './ConfigTable'
import TaskFlowCard from './TaskFlowCard'
import TuttiSnackbar from '@/components/ui/TuttiSnackbar'

export default {
    components: {
        PageToolbar,
        ConfigTable,
        TaskFlowCard,
        TuttiSnackbar
    },
    data: () => ({
        templateColor: "blue-grey lighten-4",
        flow: null,
        config: null
    }),
    props: ["client", "projectName"],
    methods: {
        async loadFlow(cached = true){
            if(this.projectName) {
                try {
                    const scheme = await this.client.resource.getProjectScheme({
                            cached,
                            project_name: this.projectName
                        });
                    this.flow = scheme.flow;
                    this.config = scheme.config;
                    this.$refs.snackbar.show("success", "successfully loaded flow");
                } catch (e) {
                    this.$refs.snackbar.show("error", "Error in loading flow: " + e.details);
                    console.error(e.details);
                }
            }
        },
        openSchemeEditorWindow() {
            window.open(`/code-editor/scheme?project_name=${this.projectName}`, 'schemeEditorWindow', ';');
        }
    },
    watch: {
        projectName() { this.loadFlow(); }
    },
    created() {
        this.client.invokeOnOpen(() => {
            this.loadFlow();
        });
    }
}
</script>
