<template>
    <v-dialog v-model="shown" max-width="350">
        <v-card>
            <v-card-title>
                Delete Nanotasks
            </v-card-title>
            <v-card-text>
                Are you sure you wish to delete the selected nanotasks? This operation cannot be undone.
            </v-card-text>
            <tutti-dialog-actions :actions="actions" />
        </v-card>
    </v-dialog>
</template>
<script>
import TuttiDialogActions from '@/components/ui/TuttiDialogActions'

export default {
    components: { TuttiDialogActions },
    data() {
        return {
            shown: false,
            actions: [
                { 
                    label: 'cancel',
                    callback: () => { this.shown = false; }
                },
                {
                    label: 'submit',
                    color: 'error',
                    callback: () => {
                        this.$emit('submit');
                        this.shown = false;
                    }
                }
            ]
        }
    }
}
</script>
