<template>
    <v-dialog v-model="shown" max-width="800">
        <v-card>
            <v-card-title>
                Create Nanotasks
            </v-card-title>
            <v-card-text>
                <v-text-field label="Nanotask group name" v-model="name" step="1" />
            </v-card-text>
            <tutti-dialog-actions :actions="actions" />
        </v-card>
    </v-dialog>
</template>
<script>
import TuttiDialogActions from '@/components/ui/TuttiDialogActions'

export default {
    components: { TuttiDialogActions },
    data() {
        return {
            shown: false,
            name: '',
            actions: [
                { 
                    label: 'cancel',
                    callback: () => { this.shown = false; }
                },
                {
                    label: 'submit',
                    color: 'indigo',
                    callback: () => {
                        this.$emit('submit', this.name);
                        this.shown = false;
                    }
                }
            ]
        }
    }
}
</script>
