<template>
    <div>
    <v-card class="pa-3 ma-3" :color="cardColor" :tile="isBatch" :shaped="!isBatch">
        <v-container>
            <v-row>
                <v-col cols="10" md="6">
                        <v-text-field v-model="node.name" :label="isBatch ? 'Batch name' : 'Template name'" prepend-inner-icon="mdi-pencil" outlined filled hide-details dense />
                </v-col>

                <v-spacer></v-spacer>

                <v-menu bottom left offset-y v-if="!isBatch">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-on="on" v-bind="attrs"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="$refs.dlgImport.show=true">
                            <v-list-item-title>Import Nanotasks...</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="$refs.dlgCreate.shown=true">
                            <v-list-item-title>Create Nanotasks...</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-row>

            <v-row class="text-caption">
                <v-col cols="12" class="pb-0" v-if="node.statement==client.ENUM.Statement.IF">
                    <v-icon>mdi-comment-question-outline</v-icon>
                    IF condition
                    <v-card height=100><codemirror v-model="node.condition" :options="cmOptions" /></v-card>
                </v-col>
                <v-col cols="12" class="pb-0" v-if="node.statement==client.ENUM.Statement.WHILE">
                    <v-icon>mdi-repeat</v-icon>
                    LOOP condition
                    <v-card height=100><codemirror v-model="node.condition" :options="cmOptions" /></v-card>
                </v-col>
                <v-col cols="12" class="pb-0" v-if="node.skippable">
                    <v-icon>mdi-transit-skip</v-icon>
                    Skippable
                </v-col>
                <v-col cols="12" class="pb-0" v-if="hasNanotask">
                    <v-chip dark label outlined color="indigo" @click="$refs.dlgList.show=true">
                        <v-icon left>mdi-file-document-multiple-outline</v-icon>
                        Nanotasks ({{ nanotasks.length }})
                    </v-chip>
                </v-col>
            </v-row>

            <v-row justify="end" :key="idx" v-for="(child, idx) in children">
                <v-col cols="12" md="11">
                <recursive-batch
                    :client="client"
                    :name="name"
                    :parent-params="{
                        projectName, templateColor,
                        node: child,
                        isLast: idx==children.length-1,
                        depth: depth+1
                    }" />
                </v-col>
            </v-row>

        </v-container>
    </v-card>
    <arrow v-if="!isLast" :depth="depth" :color="templateColor" />

    <dialog-import :client="client" :projectName="projectName" :template="node.name" ref="dlgImport" />
    <dialog-create :client="client" :projectName="projectName" :template="node.name" ref="dlgCreate" />
    <dialog-list :client="client" :projectName="projectName" :template="node.name" :nanotasks="nanotasks" ref="dlgList" />

    </div>
</template>

<script>
import RecursiveBatch from './RecursiveBatch'
import Arrow from './Arrow'
import DialogImport from './DialogImportNanotasks'
import DialogCreate from './DialogCreateNanotasks'
import DialogList from './DialogListNanotasks'

import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/python/python'
import 'codemirror/theme/base16-light.css'

export default {
    name: "RecursiveBatch",
    components: {
        RecursiveBatch,
        Arrow,
        DialogImport,
        DialogCreate,
        DialogList,
        codemirror
    },
    data: () => ({
        color: "grey",
        cmOptions: {
            tabSize: 4,
            mode: 'text/x-python',
            lineNumbers: true,
            line: true,
            readOnly: 'nocursor'
        },
        nanotasks: []
    }),
    props: ['client', 'name', 'parentParams'],
    computed: {
        cardColor() { return this.isBatch ? `${this.color} lighten-${this.depth+2}` : this.templateColor; },
        children() { return this.node ? this.node.children : []; },
        isBatch() { return this.children ? this.children.length>0 : false; },
        hasNanotask() { return !this.isBatch && this.nanotasks.length>0; },

        projectName() { return this.parentParams.projectName; },
        node() { return this.parentParams.node; },
        templateColor() { return this.parentParams.templateColor; },
        depth() { return this.parentParams.depth; },
        isLast() { return this.parentParams.isLast; },
    },
    methods: {
        dataIsForMyComponent(data) {
            return data.project_name==this.projectName && data.template_name==this.node.name;
        },
        async listNanotasks() {
            const data = await this.client.resource.listNanotasks({
                    project_name: this.projectName,
                    template_name: this.node.name
                });
            if(this.dataIsForMyComponent(data))  this.nanotasks = data.nanotasks;
        }
    },
    created() {
        this.client.invokeOnOpen(() => {
            this.client.resource.on(["createNanotasks", "deleteNanotasks"], {
                success: () => {
                    this.listNanotasks();
                }
            });

            if(!this.isBatch){ this.listNanotasks(); }
        });
    }
}
</script>
<style>
.CodeMirror {
    font-family: Monaco, 'Andale Mono', 'Lucida Console', 'Bitstream Vera Sans Mono', 'Courier New';
    height: 100px;
}
.CodeMirror-vscrollbar, .CodeMirror-hscrollbar, .CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
    z-index: 3;
}
</style>
