var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    { staticClass: "grey lighten-4" },
    [
      _c("page-toolbar", {
        attrs: { title: "Scheme" },
        scopedSlots: _vm._u([
          {
            key: "contents",
            fn: function () {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: { text: "", color: "indigo" },
                    on: { click: _vm.openSchemeEditorWindow },
                  },
                  [
                    _c("v-icon", { attrs: { left: "" } }, [
                      _vm._v("mdi-file-code-outline"),
                    ]),
                    _vm._v(" Open File Editor "),
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    attrs: { text: "", color: "indigo" },
                    on: {
                      click: function ($event) {
                        return _vm.loadFlow(false)
                      },
                    },
                  },
                  [
                    _c("v-icon", { attrs: { left: "" } }, [
                      _vm._v("mdi-refresh"),
                    ]),
                    _vm._v(" Refresh Scheme "),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "v-row",
        { staticClass: "justify-center" },
        [
          _c(
            "v-col",
            { staticClass: "pa-8 pr-4", attrs: { cols: "11", lg: "5" } },
            [_c("config-table", { attrs: { config: _vm.config } })],
            1
          ),
          _c(
            "v-col",
            { staticClass: "pa-8 pl-4", attrs: { cols: "11", lg: "7" } },
            [
              _c("task-flow-card", {
                attrs: {
                  client: _vm.client,
                  projectName: _vm.projectName,
                  flow: _vm.flow,
                  "template-color": "blue-grey lighten-4",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("tutti-snackbar", { ref: "snackbar" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }