var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { tile: "" } },
    [
      _c("v-card-title", [_vm._v(" Task Flow ")]),
      _c(
        "v-container",
        [
          _c("v-row", [_c("v-col", { attrs: { align: "right" } })], 1),
          _c(
            "v-card",
            {
              staticClass: "mx-auto py-2 text-h6",
              attrs: { align: "center", color: "grey lighten-2", width: "200" },
            },
            [_vm._v(" Start ")]
          ),
          _c("arrow", { attrs: { color: _vm.templateColor, depth: "1" } }),
          _vm.flow
            ? _c(
                "div",
                [
                  _c("recursive-batch", {
                    attrs: {
                      client: _vm.client,
                      "parent-params": {
                        projectName: _vm.projectName,
                        templateColor: _vm.templateColor,
                        node: _vm.flow,
                        depth: 1,
                        isLast: true,
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("arrow", { attrs: { color: _vm.templateColor, depth: "1" } }),
          _c(
            "v-card",
            {
              staticClass: "mx-auto py-2 text-h6",
              attrs: { align: "center", color: "grey lighten-2", width: "200" },
            },
            [_vm._v(" End ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }