var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "1400", persistent: "" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "headline" },
            [
              _c(
                "v-icon",
                { staticClass: "mr-2", attrs: { color: "indigo" } },
                [_vm._v("mdi-file-upload")]
              ),
              _vm._v(" Import Nanotasks for '" + _vm._s(_vm.template) + "' "),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c("v-file-input", {
                attrs: {
                  accept: ".json,.JSON",
                  "show-size": "",
                  label: ".json file to upload",
                },
                on: { change: _vm.getFileContent },
              }),
            ],
            1
          ),
          _c("nanotask-table", {
            attrs: { nanotasks: _vm.contents, search: _vm.search },
          }),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { text: "" }, on: { click: _vm.closeDialog } },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    disabled: _vm.contents.length == 0,
                  },
                  on: { click: _vm.importNanotasks },
                },
                [_vm._v(" Import ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }