var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "1400" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "headline" },
            [
              _c(
                "v-icon",
                { staticClass: "mr-2", attrs: { color: "indigo" } },
                [_vm._v("mdi-database-check")]
              ),
              _vm._v(" Imported Nanotasks for '" + _vm._s(_vm.template) + "' "),
              _c("v-spacer"),
              _c("v-text-field", {
                attrs: {
                  "single-line": "",
                  "hide-details": "",
                  "append-icon": "mdi-magnify",
                  label: "Search",
                },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
            ],
            1
          ),
          _vm.selectedNanotaskIds.length > 0
            ? _c(
                "v-card-text",
                { staticClass: "text-end" },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "mx-3",
                                        on: {
                                          click: function ($event) {
                                            _vm.$refs.dialogCreateNanotaskGroup.shown = true
                                          },
                                        },
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_c("v-icon", [_vm._v("mdi-group")])],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3662933864
                      ),
                    },
                    [_c("span", [_vm._v("Create nanotask group")])]
                  ),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "mx-3",
                                        attrs: { dark: "", color: "error" },
                                        on: {
                                          click: function ($event) {
                                            _vm.$refs.dialogDeleteNanotasks.shown = true
                                          },
                                        },
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_c("v-icon", [_vm._v("mdi-delete")])],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1730989114
                      ),
                    },
                    [_c("span", [_vm._v("Delete")])]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("nanotask-table", {
            attrs: { nanotasks: _vm.nanotasks, search: _vm.search },
            on: { select: _vm.setSelectedNanotasks },
          }),
          _c("v-card-text", [
            _c(
              "span",
              { staticClass: "text-h6" },
              [
                _c("v-icon", { attrs: { color: "indigo" } }, [
                  _vm._v("mdi-group"),
                ]),
                _vm._v(" Nanotask Groups"),
              ],
              1
            ),
          ]),
          _c("nanotask-group-table", {
            attrs: {
              nanotaskGroups: _vm.nanotaskGroups,
              search: _vm.searchGroup,
            },
            on: { delete: _vm.deleteNanotaskGroup },
          }),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { text: "" }, on: { click: _vm.closeDialog } },
                [_vm._v("Close")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("delete-nanotasks-dialog", {
        ref: "dialogDeleteNanotasks",
        on: { submit: _vm.deleteNanotasks },
      }),
      _c("create-nanotask-group-dialog", {
        ref: "dialogCreateNanotaskGroup",
        on: { submit: _vm.createNanotaskGroup },
      }),
      _c("tutti-snackbar", { ref: "snackbar" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }