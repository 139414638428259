<template>
    <v-dialog v-model="show" max-width="1400">
        <v-card>
            <v-card-title class="headline">
                <v-icon class="mr-2" color="indigo">mdi-database-check</v-icon>
                Imported Nanotasks for '{{ template }}'

                <v-spacer/>

                <v-text-field
                    single-line
                    hide-details
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    />
            </v-card-title>

            <v-card-text v-if="selectedNanotaskIds.length>0" class="text-end">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" class="mx-3" @click="$refs.dialogCreateNanotaskGroup.shown=true"><v-icon>mdi-group</v-icon></v-btn>
                    </template>

                    <span>Create nanotask group</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" class="mx-3" dark color="error" @click="$refs.dialogDeleteNanotasks.shown=true"><v-icon>mdi-delete</v-icon></v-btn>
                    </template>

                    <span>Delete</span>
                </v-tooltip>

            </v-card-text>

            <nanotask-table
                :nanotasks="nanotasks"
                :search="search"
                @select="setSelectedNanotasks"
                />

            <v-card-text>
                <span class="text-h6"><v-icon color="indigo">mdi-group</v-icon> Nanotask Groups</span>
            </v-card-text>
            <nanotask-group-table
                :nanotaskGroups="nanotaskGroups"
                :search="searchGroup"
                @delete="deleteNanotaskGroup"
                />

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="closeDialog" >Close</v-btn>
            </v-card-actions>
        </v-card>

        <delete-nanotasks-dialog ref="dialogDeleteNanotasks" @submit="deleteNanotasks" />
        <create-nanotask-group-dialog ref="dialogCreateNanotaskGroup" @submit="createNanotaskGroup" />

        <tutti-snackbar ref="snackbar" />
    </v-dialog>
</template>

<script>
import NanotaskTable from './NanotaskTable'
import NanotaskGroupTable from './NanotaskGroupTable'
import DeleteNanotasksDialog from './DeleteNanotasksDialog'
import CreateNanotaskGroupDialog from './CreateNanotaskGroupDialog'
import TuttiSnackbar from '@/components/ui/TuttiSnackbar'

export default {
    data: () => ({
        show: false,
        search: '',
        searchGroup: '',
        selectedNanotasks: [],
        nanotaskGroups: [],
    }),
    props: ['client', 'projectName', 'template', 'nanotasks'],
    components: {
        NanotaskTable,
        NanotaskGroupTable,
        DeleteNanotasksDialog,
        CreateNanotaskGroupDialog,
        TuttiSnackbar
    },
    computed: {
        selectedNanotaskIds() {
            return this.selectedNanotasks.map((x) => (x.id));
        }
    },
    methods: {
        setSelectedNanotasks(nanotasks) {
            this.selectedNanotasks = nanotasks;
        },
        closeDialog() {
            this.show = false;
        },
        async deleteNanotasks() {
            await this.client.resource.deleteNanotasks({
                nanotask_ids: this.selectedNanotaskIds
            });
            this.setSelectedNanotasks([]);
        },
        async createNanotaskGroup(name) {
            await this.client.resource.createNanotaskGroup({
                name,
                nanotask_ids: this.selectedNanotaskIds,
                project_name: this.projectName,
                template_name: this.template,
            });
            this.$refs.snackbar.show('success', `Created nanotask group "${name}"`);
            this.refreshNanotaskGroups();
        },
        async refreshNanotaskGroups() {
            this.nanotaskGroups = await this.client.resource.listNanotaskGroups({
                    project_name: this.projectName,
                    template_name: this.template,
                });
        },
        async deleteNanotaskGroup(id, name) {
            await this.client.resource.deleteNanotaskGroup({ nanotask_group_id: id })
            this.refreshNanotaskGroups();
            this.$refs.snackbar.show('success', `Deleted nanotask group "${name}"`);
        }
    },
    mounted() {
        this.refreshNanotaskGroups();
    }
}
</script>
